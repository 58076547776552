<template>
  <div>
    <component
      :is="computedComponentName"
      v-model="shouldShowModal"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-center justify-between filter-title"
          :class="{ 'font-weight-bold': isFilterApplied }"
          v-on="on"
          v-bind="attrs"
          >
          <div class="d-flex align-center flex-grow-1 relative">
            <v-icon left>
              public
            </v-icon>
            Ethnicity
          </div>
          <v-icon>
            expand_more
          </v-icon>
        </div>
      </template>
      <v-card>
        <v-card-title class="d-md-none">
          Ethnicity
        </v-card-title>

        <v-card-text
          class="pt-md-3"
          :class="{ 'black--text': isThemeLight }"
        >
          <v-row>
            <!-- Audience Section -->
            <v-col cols="12">
              <div class="mb-1 d-flex">
                <div class="d-flex align-center flex-grow-1">
                  <v-icon
                    :class="{ 'black--text': isThemeLight }"
                    class="mr-1"
                    left
                  >
                    people
                  </v-icon>

                  Audience
                </div>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="isThemeLight ? 'black' : null"
                      v-bind="attrs"
                      v-on="on"
                    >
                      info
                    </v-icon>
                  </template>

                  <span>
                    {{ tooltipText }}
                  </span>
                </v-tooltip>
              </div>

              <v-radio-group
                v-model="form.value"
                @change="submitForm"
                hide-details
              >
                <v-radio
                  v-for="(item, index) in options"
                  :key="index"
                  :label="item.label"
                  :value="index"
                ></v-radio>
              </v-radio-group>

              <!-- Show this weight selector if the value is either male or female -->
              <weight-selector
                v-model="form.weight"
                @change="submitForm"
                class="mt-3"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </component>
  </div>
</template>

<script>
const WeightSelector = () => import(/* webpackChunkName: "weight-selector" */ "@/blocks/common/selectors/WeightSelector.vue")

// used throughout the code
const filterName = "ethnicity"

const originalForm = () => ({
  value: null,
  weight: 0.3
})

export default {
  name: "FilterEthnicity",

  components: {
    WeightSelector,
  },

  props: {
    platform: String
  },

  data: () => ({
    form: originalForm(),

    shouldShowModal: false,

    options: [
      {
        label: "Any",
        value: null,
      },
      {
        label: "Black",
        value: "Black",
      },
      {
        label: "Asian",
        value: "Asian",
      },
      {
        label: "White",
        value: "White",
      },
      {
        label: "Hispanic",
        value: "Hispanic",
      }
    ],

    tooltipText: "Identify influencers by their audience ethnicity. We determine ethnicity of audience by analyzing profile photos, name and text in profile bio and selfies in recent posts as well as their post locations."
  }),

  computed: {
    // show the filter as a dialog for mobile dialogs
    computedComponentName() {
      return this.isDesktopDevice ? "v-menu" : "v-dialog"
    },

    appliedFilter() {
      return this.$store.getters["influencerDiscovery/findFilterByType"](filterName)
    },

    // Used to bold the filter name text
    isFilterApplied() {
      return Boolean(this.$store.getters["influencerDiscovery/findFiltersByType"](filterName).length)
    }
  },

  methods: {
    /**
     * Takes an item and generates the text based weight
     */
    computeFilterText({ label }) {
      return `Audience Ethnicity: ${label} | >${this.form.weight * 100}%`
    },

    /**
     * Push the data from either of the inputs to the filters array in Vuex Store
     */
    async submitForm() {
      const selectedItem = this.options[this.form.value]

      // if there's some value, then update the filter
      if (selectedItem.value) {
        this.$store.dispatch("influencerDiscovery/replaceFilter", {
          type: filterName,
          data: {
            color: "pink lighten-5",
            icon: "public",
            iconColor: "pink",
            text: this.computeFilterText({ label: selectedItem.label }),
            inputs: {
              ...selectedItem,
              weight: this.form.weight || null
            }
          }
        })
      }
      // Otherwise
      else if (this.appliedFilter) {
        // Remove the filter
        this.$store.dispatch("influencerDiscovery/removeFilter", this.appliedFilter.id)
      }
    },
  },

  mounted() {
    // Subscribe to window event when the chip is closed
    window.addEventListener("removeFilter", (e) => {
      // If it's not the one we want, don't execute the code
      if (e.detail.module !== "influencerDiscovery" || e.detail.item.type !== filterName) return

      // Now that it is the one we want to see, continue
      this.form.value = 0
    })

    // Subscribe to window event when the chip is closed
    window.addEventListener("addFilter", (e) => {
      // If it's not the one we want, don't execute the code
      if (e.detail.module !== "influencerDiscovery" || e.detail.item.type !== filterName) return

      // Now that it is the one we want to see, continue
      const index = this.options.findIndex((search) => search.value === e.detail.item.data.inputs.value)
      this.form.value = index
    })
  }
}
</script>
